@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ProseMirror {
  min-height: 8em;
  margin: 2em 0;
  padding: 1em;
}
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror pre .hljs-comment, .ProseMirror pre .hljs-quote {
  color: #616161;
}
.ProseMirror pre .hljs-variable, .ProseMirror pre .hljs-template-variable, .ProseMirror pre .hljs-attribute, .ProseMirror pre .hljs-tag, .ProseMirror pre .hljs-name, .ProseMirror pre .hljs-regexp, .ProseMirror pre .hljs-link, .ProseMirror pre .hljs-selector-id, .ProseMirror pre .hljs-selector-class {
  color: #f98181;
}
.ProseMirror pre .hljs-number, .ProseMirror pre .hljs-meta, .ProseMirror pre .hljs-built_in, .ProseMirror pre .hljs-builtin-name, .ProseMirror pre .hljs-literal, .ProseMirror pre .hljs-type, .ProseMirror pre .hljs-params {
  color: #fbbc88;
}
.ProseMirror pre .hljs-string, .ProseMirror pre .hljs-symbol, .ProseMirror pre .hljs-bullet {
  color: #b9f18d;
}
.ProseMirror pre .hljs-title, .ProseMirror pre .hljs-section {
  color: #faf594;
}
.ProseMirror pre .hljs-keyword, .ProseMirror pre .hljs-selector-tag {
  color: #70cff8;
}
.ProseMirror pre .hljs-emphasis {
  font-style: italic;
}
.ProseMirror pre .hljs-strong {
  font-weight: 700;
}
.ProseMirror h3 {
  font-weight: normal;
  font-size: 2em;
}
